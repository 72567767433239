require('../css/style.css');
require('../css/slider.css');
require('../css/faq.css');


const sliderTriggerWidth = 1025;

window.addEventListener('DOMContentLoaded', run);

async function run(e) {

    handleSlides({
        wrapper: '.project-listing .slider-wrapper',
        nav: '.project-listing .slider-nav'
    });


    document.body.addEventListener('click', e => {

        if (e.target.classList.contains('scroll-to-link')) {
            scrollTo(e);
            scrollIntoView();
        }
        else if (e.target.classList.contains('faq-btn') || e.target.classList.contains('faq-title')) {
            toggleFaq(e.target, {
                collapseOthers: true
            });
        }
    });

   
}

function scrollTo(e) {

    e.preventDefault();
    const link = e.target;
    const id = link.href.slice(link.href.lastIndexOf('#') + 1);
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth' });

    if (link.dataset.tab) {
        document.getElementById(link.dataset.tab).click();
    }
}

function toggleFaq(faqBtn, options = {}) {

    const faq = faqBtn.closest('.faq');
    const faqs = Array.from(faq.parentElement.children);
    const isLast = faqs.indexOf(faq) === faqs.length - 1;

    if (faq.classList.contains('collapsed')) {

        if (Object(options).collapseOthers) {
            const wrapper = faqBtn.closest('.faq-wrapper');
            const faqs = wrapper.querySelectorAll('.faq');
            if (faqs.length) {
                faqs.forEach(item => {
                    item.classList.remove('expanded');
                    item.classList.add('collapsed');
                });
            }
        }

        faq.classList.remove('collapsed');
        faq.classList.add('expanded');
    } else {
        faq.classList.remove('expanded');
        faq.classList.add('collapsed');
    }

    if (isLast) {
        // faq.scrollIntoView();
        scrollToElement(faq, 'auto');
    }
}



function handleSlides(params) {
    let activeIndex = 0;
    let touchstartX = 0;
    let touchstartY = 0;
    let touchendX = 0;
    let touchendY = 0;

    const nav = document.querySelector(params.nav);
    const wrapper = document.querySelector(params.wrapper);
    let slides = wrapper.querySelectorAll('.slide');
    slide();

    window.addEventListener('resize', e => {
        slides = wrapper.querySelectorAll('.slide');
        slide();
    });

    wrapper.addEventListener(
        'touchstart',
        event => {
            touchstartX = event.changedTouches[0].screenX;
            touchstartY = event.changedTouches[0].screenY;
        },
        false
    );

    wrapper.addEventListener(
        'touchend',
        event => {
            touchendX = event.changedTouches[0].screenX;
            touchendY = event.changedTouches[0].screenY;
            handleSwipe(event);
        },
        false
    );

    nav.addEventListener('click', e => {
        if (e.target.classList.contains('nav-item')) {
            activeIndex = Array.from(nav.children).indexOf(e.target);
            slide();
        }
    });

    function handleSwipe(e) {
        let caseCaptured = false;

        if (touchendX < touchstartX && Math.abs(touchendX - touchstartX) > 32) {
            // Swipe left
            caseCaptured = true;
            if (activeIndex < slides.length - 1) {
                activeIndex++;
                slide();
            }
        } else if (touchendX > touchstartX && Math.abs(touchendX - touchstartX) > 30) {
            // Swipe right
            caseCaptured = true;
            if (!activeIndex) {
                // If user swipes left and the slider is on the first image -> do nothing
                return;
            }

            activeIndex--;
            slide();
        }

        if (caseCaptured) {
            e.preventDefault();
        }
    }

    function slide() {
        if (window.innerWidth > sliderTriggerWidth) {
            return;
        }

        const idx = activeIndex;

        Array.from(nav.children).forEach((item, i) =>
            idx == i ? item.classList.add('active') : item.classList.remove('active')
        );

        setTimeout(() => {
            if (!slides.length) {
                return;
            }
            const activeSlide = slides[idx];
            const activeSlideW = activeSlide.offsetWidth;
            const parentW = wrapper.offsetWidth / 2;
            const marginLeft =
                idx === 0
                    ? (parentW - activeSlideW / 1.7).toFixed()
                    : (function measure() {
                          let value = 0;
                          for (let i = 0; i < idx; i++) {
                              value += slides[i].offsetWidth;
                          }
                          const margin =
                              parseInt(window.getComputedStyle(slides[0]).marginRight) * 2;
                          const itemsWidth = value + margin * (idx - 1);
                          const x = parentW - (margin + activeSlideW / 2);
                          return -(itemsWidth - x);
                      })();

            slides[0].style.marginLeft = marginLeft + 'px';
        }, 50);
    }
}


